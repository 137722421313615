import React from 'react';
import { ListProps } from './List.type.js';
import { ListItem } from './ListItem/index.js';

export const List: React.FC<ListProps> = ({ announces, regionsByCode }) => (
  <>
    {announces.map((announce: any) => (
      <ListItem
        key={announce.id}
        announce={announce}
        regionsByCode={regionsByCode}
      />
    ))}
  </>
);
