export const ecoList = [
    {
        name: 'houses',
        title: 'Icona',
    },
    {
        name: 'badge',
        title: 'Закупки',
    },
    {
        name: 'folder',
        title: 'ИД',
    },
    {
        name: 'note',
        title: 'Акты',
    },
];
