import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import React from 'react';
import styles from './Main.module.scss';
import { MainPageProps } from '../../pages/index.js';
// import { Search } from './Search/index.js';
import { Col } from '../Purchases/components/Col.js';
import { Row } from '../Purchases/components/Row.js';
import { Link } from '@/npm/next.js';
import { rootLinks } from '../Links.js';
import { ArrowUpRightIcon, RigthArrowIcon } from '../Icons/index.js';
import { Purchases } from './Purchases/index.js';
import Loader from '../Purchases/components/Loader.js';
import { useRouter } from 'next/router.js';
import { ecoList } from './ecosystem.js';
import { mainTariffsDummy } from '../_dummyData/tariffs.js';
import { useLayoutContext } from '../LayoutContext.js';
import { Button } from '@/base-components/Button.js';
import clsx from 'clsx';
import keyBy from 'lodash/keyBy.js';
import { List } from '../Purchases/List/index.js';

export type MainProps = MainPageProps;

export const Main: FC<MainProps> = ({ announces, regions, stats }) => {
  // return (
  //   <div className={styles.stub}>
  //     <Loader />
  //   </div>
  // );
  // console.log('announces', announces);
  const { offerLinks } = useLayoutContext();
  const isTabsVisible = false;
  const blocks = {
    cashback: useRef<HTMLDivElement>(null),
    eko: useRef<HTMLDivElement>(null),
    tariffs: useRef<HTMLDivElement>(null),
  };
  const { asPath, push } = useRouter();
  const isBrowser = typeof window !== 'undefined';
  const [windowWidth, setWindowWidth] = useState(
    isBrowser ? window.innerWidth : 1120,
  );
  const [tabs, setTabs] = useState({
    build: {
      title: 'Строительство',
      isActive: true,
      isVisible: true,
    },
    materials: {
      title: 'Материалы',
      isActive: false,
      isVisible: true,
    },
    tech: {
      title: 'Аренда техники',
      isActive: false,
      isVisible: true,
    },
  });
  const [activeTabIdx, setActiveTabIdx] = useState(0);

  const regionsByCode = useMemo(() => keyBy(regions, 'regionCode'), [regions]);

  const handleResize = useCallback(() => {
    setWindowWidth(isBrowser ? window.innerWidth : 1120);
  }, [isBrowser]);
  useEffect(() => {
    return window.addEventListener('resize', handleResize);
  }, [handleResize]);

  const scrollToBlock = (path: string) => {
    const block = path.split('#')?.[1] as keyof typeof blocks;
    if (block in blocks && blocks[block]?.current) {
      blocks[block].current?.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    scrollToBlock(asPath);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asPath]);

  const onEnter = useCallback(() => {
    push(rootLinks.auth.url);
  }, [push]);

  const goToPurchases = useCallback(() => {
    push(rootLinks.purchases.url);
  }, [push]);

  const handleChangeTab = useCallback((tabName: keyof typeof tabs) => {
    setTabs((oldState) => {
      return Object.keys(oldState).reduce(
        (acc, curr, idx) => {
          acc[curr as keyof typeof tabs] = {
            ...oldState[curr as keyof typeof tabs],
            isActive: tabName === curr,
          };
          if (curr === tabName) {
            setActiveTabIdx(idx);
          }
          return acc;
        },
        {} as typeof tabs,
      );
    });
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.bg}>
        <Row className={styles.wrapper}>
          <Col>
            <h2>Praktis - закупки</h2>
            <h3>Разработано застройщиком для застройщиков</h3>
            <p>Простая система для сложных закупок</p>
            <Button
              onClick={onEnter}
              variant="blue"
              size="large"
              className={styles.action}
            >
              <span>Стать партнером за 5 мин</span>
              <ArrowUpRightIcon />
            </Button>
          </Col>
          <Col className={styles.logo} />
        </Row>
      </div>
      <Col className={styles.statistics}>
        <Row className={styles.wrapper}>
          <Col>
            <caption>{stats.allPurchases}</caption>
            <h4>всего сделок</h4>
          </Col>
          <Col>
            <caption>{stats.closedDealsSum}</caption>
            <h4>сумма закрытых сделок</h4>
          </Col>
        </Row>
      </Col>
      <div className={styles.purchases}>
        <div className={styles.wrapper}>
          {isTabsVisible && (
            <Row className={styles.tabs}>
              {Object.keys(tabs)
                .map((tabName) => {
                  const tab = tabs[tabName as keyof typeof tabs];
                  return tab.isVisible ? (
                    <Row
                      key={tabName}
                      className={clsx(
                        styles.tab,
                        tab.isActive && styles.active,
                      )}
                      onClick={() =>
                        handleChangeTab(tabName as keyof typeof tabs)
                      }
                    >
                      {tab.title}
                    </Row>
                  ) : null;
                })
                .filter(Boolean)}
            </Row>
          )}
          <Row
            className={styles.tabBody}
            style={
              isTabsVisible ? undefined : { borderTopLeftRadius: '1.25rem' }
            }
          >
            <Col className={styles.stats}>
              <Col suppressHydrationWarning>
                <caption>{stats.closedDeals}</caption>
                <h4>закрытых</h4>
              </Col>
              <Col suppressHydrationWarning>
                <caption>{stats.activeAnnounces}</caption>
                <h4>активных</h4>
              </Col>
              <Button
                onClick={goToPurchases}
                variant="blue"
                size="large"
                className={styles.action}
              >
                <span>Полный список</span>
                <ArrowUpRightIcon />
              </Button>
            </Col>
            {/* <Purchases announces={announces.slice(activeTabIdx * 2, activeTabIdx * 2 + 2)} /> */}
            <Col className={styles.purchasesList}>
              <List
                announces={announces.slice(
                  activeTabIdx * 2,
                  activeTabIdx * 2 + 2,
                )}
                regionsByCode={regionsByCode}
              />
            </Col>
          </Row>
        </div>
      </div>
      <div className={styles.cashback} ref={blocks.cashback}>
        <Row className={styles.wrapper}>
          <Col>
            <h2>Как тебе CashBack?</h2>
            <p>
              Размещай свои тендеры и получай приятный бонус для участия в
              Тендерах Setl Group
            </p>
          </Col>
          <Col />
        </Row>
      </div>
      <div className={styles.tariffs} ref={blocks.tariffs}>
        <Col className={styles.wrapper}>
          <h2>Тарифы</h2>
          <Row className={styles.tariffsList}>
            {mainTariffsDummy.map((t) => {
              return (
                <Col key={t.topText}>
                  <p>{t.topText}</p>
                  <Row>
                    <h3>{t.central.main}</h3>
                    {!!t.central.additional && <h4>{t.central.additional}</h4>}
                  </Row>
                  {!!t.bottomText && <p>{t.bottomText}</p>}
                </Col>
              );
            })}
          </Row>
          <Col className={styles.description}>
            <p>
              Тарифы облагаются НДС по ставке, предусмотренной действующим
              законодательством Российской Федерации.
            </p>
            <p>
              «Оплата с победителя» позволяет участнику подать заявку на закупку
              при наличии требуемой суммы на лицевом счёте. Сумма будет
              заблокирована в момент подачи заявки. После публикации итогового
              протокола происходит списание с победителя заблокированных средств
              в размере тарифа. Участникам, не победившим в закупке, сумма
              разблокируется.
            </p>
          </Col>
          <Row className={styles.links}>
            <Row>
              <span></span>
              <a href={offerLinks?.[0]?.link || ''} download>
                Оферта на заключение лицензионного договора
              </a>
            </Row>
            <Row>
              <span></span>
              <Link href={rootLinks.main.url}>Инструкция работы на ЭТП</Link>
            </Row>
            <Row>
              <span></span>
              <a href={offerLinks?.[1]?.link || ''} download>
                Соглашение об обработке персональных данных
              </a>
            </Row>
            <Row>
              <span></span>
              <Link href={rootLinks.programs.url}>Программные модули</Link>
            </Row>
          </Row>
        </Col>
      </div>
      <div className={styles.eko} ref={blocks.eko}>
        <Col className={styles.wrapper}>
          <h3>
            Экосистема Praktis - разработано застройщиком для застройщиков
          </h3>
          <p>Выберите для себя необходимые для работы сервисы</p>
          <Row>
            {ecoList.map((eco) => {
              return (
                <Col className={styles.ecoItem} key={eco.name}>
                  <div className={styles[eco.name]} />
                  <p>{eco.title}</p>
                </Col>
              );
            })}
          </Row>
        </Col>
      </div>
    </div>
  );
};
